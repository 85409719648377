import React from "react"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import { Flex } from '../components/Grid'
import styled from '../../util/style'

const Wrapper = styled(Flex)`
  height: 100%;
`

const Factsheet = () => (
  <div>
    TODO: add dropdown bar
  </div>
)

export default Factsheet